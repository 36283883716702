import React from 'react'
import styled, { keyframes } from 'styled-components'
import { CogIcon } from '../../../components/Svg'
import IconButton from '../../../components/Button/IconButton'
import Text from '../../../components/Text/Text'
import Link from '../../../components/Link/Link'
import { MENU_ENTRY_HEIGHT } from '../config'
import { PanelProps, PushedProps } from '../types'
import CakePrice from './CakePrice'
import SocialLinks from './SocialLinks'

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
`

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MENU_ENTRY_HEIGHT}px;
`

const pulse = keyframes`
  0% { 
    opacity: 0.75;
    transform: scale(1);
  }
  25% {
    opacity: 0.75;
    transform: scale(2.5);
  }
  50% {
    opacity: 0.375;
    transform: scale(3);
  }
  100% { 
    opacity: 0; 
    transform: scale(3.5);
  }
`

const StyledOnlineCircle = styled.div`
  position: relative;
  width: 8px;
  height: 8px;
  background: #23b17a;
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(59, 183, 143, 0.5);
    animation: ${pulse} 5s linear infinite;
    transform-origin: center center;
  }
`

const PanelFooter: React.FC<Props> = ({ isPushed, pushNav, cakePriceUsd }) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    )
  }

  return (
    <Container>
      <SocialEntry>
        <CakePrice cakePriceUsd={cakePriceUsd} />
        <Link external href="https://status.themedievalage.com" color="text" ml="12px">
            <StyledOnlineCircle />
            <Text small ml="6px">
              Status
            </Text>
          </Link>
      </SocialEntry>
      <SocialEntry>
        <SocialLinks />
      </SocialEntry>
    </Container>
  )
}

export default PanelFooter
