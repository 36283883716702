import { Colors } from './types'

export const baseColors = {
  primary: '#fddbac',
  primaryDark: '#004ea1',
  secondary: '#b01b1b',
  tertiary: '#353547',
  success: '#31d059',
  warning: '#ed892f',
  failure: '#f24447',
}

const rarityColors = {
  ancient: '#ffd700',
  legendary: '#ff8000',
  mythical: '#a335ee',
  rare: '#0070dd',
  uncommon: '#1eff00',
  common: '#ffffff',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
}

export const darkColors: Colors = {
  ...baseColors,
  ...rarityColors,
  ...additionalColors,
  secondary: '#c41b1b',
  background: '#000000',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#1b1b1b',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#000000',
  invertedContrast: '#191326',
  input: '#1b1b1b',
  inputSecondary: '#1b1b1b',
  primaryDark: '#191919',
  text: '#e2f2fc',
  textDisabled: '#666171',
  textSubtle: '#fddbac',
  borderColor: '#524B63',
  gradients: {
    rainbow: 'linear-gradient(240deg, rgba(255,0,0,0.7) 0%, rgba(255,154,0,0.7) 10%, rgba(208,222,33,0.7) 20%, rgba(79,220,74,0.7) 30%, rgba(63,218,216,0.7) 40%, rgba(47,201,226,0.7) 50%, rgba(28,127,238,0.7) 60%, rgba(95,21,242,0.7) 70%, rgba(186,12,248,0.7) 80%, rgba(251,7,217,0.7) 90%, rgba(255,0,0,0.7) 100% )',
    gold: 'linear-gradient(180deg, #FFD800 0%, #EB8C00 100%)',
    red: 'linear-gradient(180deg, #c61b1b 0%, #8b1b1b 100%)',
    blue: 'linear-gradient(180deg, #1726e9 0%, #071778 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  },
}
