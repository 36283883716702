import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, CardHeader, Heading, LinkExternal, Text, Flex } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'

const FarmInfoDescriptionCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <Flex alignItems="center">
        <CardHeader variant="violet">
          <Heading scale="lg">{t('Yield Farm')}</Heading>
        </CardHeader>
        <CardBody>
          <Heading scale="lg" mb="8px">
            {t('What is a Farm?')}
          </Heading>
          <Text>
            {t(
              'Farm is a place you can stake your LP tokens in order to generate high returns in the form of TMA. The amount of returns will be calculated by the annual percentage rate (APR).The APR of each farm will be different and fluctuated due to the size of the pool. You can choose any farm you like to start farming now.',
            )}
          </Text>
          <Text>{t('ring your LP tokens thassd the previous step to stake in the farm afgnd earn much more TMA as a return')}</Text>
          <Flex justifyContent="flex-end" mt="8px">
            <LinkExternal href="https://docs.themedievalage.com/">Read More</LinkExternal>
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  )
}

export default FarmInfoDescriptionCard
