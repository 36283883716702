import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import { Menu, ResetCSS } from 'packages/uikit'

import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchPublicData, usePriceCakeBusd } from 'state/hooks'
import useAuth from 'hooks/useAuth'
import Footer from 'packages/uikit/components/Footer'
import MenuConfig from 'config/menu'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import GlobalStyle from './style/Global'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import Vault from './views/Vault'
import Farms from './views/Farms'

import history from './routerHistory'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Hall = lazy(() => import('./views/Hall'))
const Merchant = lazy(() => import('./views/Merchant'))
const Battleground = lazy(() => import('./views/Battleground'))
const Dungeon = lazy(() => import('./views/Dungeon'))
const Referral = lazy(() => import('./views/Referral'))

const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useEagerConnect()
  useFetchPublicData()
  useFetchPriceList()

  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage } = useTranslation()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Menu
          account={account}
          login={login}
          logout={logout}
          cakePriceUsd={cakePriceUsd.toNumber()}
          links={MenuConfig}
        >
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/hall" exact>
                <Hall />
              </Route>
              <Route path="/vault">
                <Vault />
              </Route>
              <Route path="/farms">
                <Farms />
              </Route>
              <Route path="/merchant">
                <Merchant />
              </Route>
              <Route path="/battleground">
                <Battleground />
              </Route>
              <Route path="/dungeon">
                <Dungeon />
              </Route>
              <Route path="/referral">
                <Referral />
              </Route>

              {/* Redirect */}
              <Route path="/staking">
                <Redirect to="/pools" />
              </Route>
              <Route path="/pools">
                <Redirect to="/vault" />
              </Route>

              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </SuspenseWithChunkError>
        </Menu>
        {/* <Footer langs={languageList} setLang={setLanguage} currentLang={currentLanguage.code} /> */}
        <ToastListener />
      </SuspenseWithChunkError>
    </Router>
  )
}

export default React.memo(App)
