import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import observerOptions from './options'
import Wrapper from './Wrapper'
import { ImageProps } from './types'

const waves = keyframes`
   from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`

const pulse = keyframes`
  0%,33% {
    opacity: 0.4;
  }
  50%, 66%, {
    opacity: 1;
  }
  99%, 100% {
    opacity: 0.4;
  }
`

const StyledImage = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
`

const PlaceholderRoot = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Pulse = styled(PlaceholderRoot)`
  position: relative;
  overflow: hidden;
  animation: ${pulse} 2s infinite ease-out;
  transform: translate3d(0, 0, 0);
  &:before {
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgba(243, 243, 243, 0.5), transparent);
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    animation: ${waves} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`

const Image: React.FC<ImageProps> = ({ src, alt, ...otherProps }) => {
  const imgRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const img = imgRef.current as unknown as HTMLImageElement
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry
        if (isIntersecting) {
          setIsLoaded(true)
          observer.disconnect()
        }
      })
    }, observerOptions)
    observer.observe(img)

    return () => {
      observer.disconnect()
    }
  }, [src])

  return (
    <Wrapper ref={imgRef} {...otherProps}>
      {isLoaded ? <StyledImage src={src} alt={alt} /> : <Pulse />}
    </Wrapper>
  )
}

export default Image
