import React from 'react'
import { Button, Card, CardBody, Heading, Text, MetamaskIcon, BSCScanIcon } from 'packages/uikit'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { registerToken } from 'utils/wallet'
import { useTotalSupply, useBurnedBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { getCakeAddress } from 'utils/addressHelpers'
import { usePriceCakeBusd } from 'state/hooks'

import CardValue from './CardValue'

const StyledZeroBitStats = styled(Card)`
  margin-left: auto;
  margin-right: auto;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
`

const ZeroBitStats = () => {
  const { t } = useTranslation()
  const totalSupply = useTotalSupply()
  const eggPrice = usePriceCakeBusd()
  const cakeContractAddress = getCakeAddress()
  const burnedBalance = useBurnedBalance(cakeContractAddress)
  const cakeSupply = totalSupply ? totalSupply.minus(burnedBalance) : 0
  const marketCap = eggPrice.times(cakeSupply)

  return (
    <StyledZeroBitStats isSuccess>
      <CardBody>
        <Heading scale="xl" mb="24px">
          {t('TMA Stats')}
        </Heading>
        <Row>
          <Text fontSize="16px">{t('TMA Market Cap')}</Text>
          {marketCap && <CardValue fontSize="16px" decimals={0} prefix="$" value={getBalanceNumber(marketCap)} />}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Total Minted')}</Text>
          {totalSupply && (
            <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={getBalanceNumber(totalSupply)} />
          )}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Total Burned')}</Text>
          {burnedBalance && (
            <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={getBalanceNumber(burnedBalance)} />
          )}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Circulating Supply')}</Text>
          {cakeSupply && <CardValue fontSize="16px" sufix=" TMA" value={getBalanceNumber(cakeSupply)} />}
        </Row>
        <Row>
          <Text fontSize="16px">{t('New TMA/block')}</Text>
          <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={20} />
        </Row>
        <Row>
          <Button onClick={() => registerToken(cakeContractAddress, 'TMA', 18, 'noimgyet')} width="20%" mt="15px">
            <MetamaskIcon />
          </Button>
          <Button
            as="a"
            external
            href={`https://bscscan.com/token/${cakeContractAddress}`}
            width="38%"
            ml="8px"
            mt="15px"
          >
            <BSCScanIcon mr="4px" /> {t('BSCScan')}
          </Button>
          <Button
            as="a"
            external
            href={`https://exchange.themedievalage.com/#/swap?outputCurrency=${cakeContractAddress}`}
            width="42%"
            ml="8px"
            mt="15px"
          >
            {t('Buy TMA')}
          </Button>
        </Row>
      </CardBody>
    </StyledZeroBitStats>
  )
}

export default ZeroBitStats
