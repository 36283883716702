import React from 'react'
import { Flex, Text } from 'packages/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import RulesCard from './RulesCard'
import FAQs from './FAQs'

const Wrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`

const StyledCardWrapper = styled(Flex)`
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-right: 40px;
    flex: 1;
  }
`

const Rules = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <StyledCardWrapper>
        <RulesCard title={t('Mauris pretium, velit si')}>
          <Text textAlign="center" fontSize="14px" color="textSubtle">
            {t('Proin dictum varius enim, at fringilla erat aliquet quis. Vestibulum volutpat orci eget arcu pellentesque euismod.')}
          </Text>
        </RulesCard>
        <RulesCard title={t('Pellentesque quis luctus massa')}>
          <Text textAlign="center" fontSize="14px" color="textSubtle">
            {t('Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi')}
          </Text>
        </RulesCard>
      </StyledCardWrapper>
      <FAQs />
    </Wrapper>
  )
}

export default Rules
