import { MenuEntry } from 'packages/uikit'

const MenuConfig: MenuEntry[] = [
  {
    label: 'Start',
    icon: 'GiCrown',
    href: '/',
  },

  {
    label: 'Trade',
    icon: 'GiTrade',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.themedievalage.com/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.themedievalage.com/#/pool',
      },
      {
        label: 'TMA Info',
        href: 'https://exchange.themedievalage.com/token/TMA',
      },
    ],
  },
  {
    label: 'Hall',
    icon: 'GiStoneThrone',
    href: '/hall',
  },
  {
    label: 'Vault',
    icon: 'GiLockedChest',
    href: '/vault',
  },
  {
    label: 'Farms',
    icon: 'GiFarmer',
    href: '/farms',
  },
  {
    label: 'Merchant',
    icon: 'GiAnvilImpact',
    href: '/merchant',
  },
  {
    label: 'Battleground',
    icon: 'GiBattleAxe',
    href: '/battleground',
  },
  {
    label: 'Dungeon',
    icon: 'GiSpikedDragonHead',
    href: '/dungeon',
  },
  {
    label: 'Referring',
    icon: 'GiBeech',
    href: '/referral',
  },
  {
    label: 'More',
    icon: 'GiSuits',
    items: [
      {
        label: 'Audit & Security',
        href: '/security',
      },
      {
        label: 'Request Form',
        href: '/request-form',
      },
      {
        label: 'Status',
        href: 'https://status.themedievalage.com',
      },
      {
        label: 'Voting',
        href: 'https://voting.themedievalage.com',
      },
      {
        label: 'Documentation',
        href: 'https://docs.themedievalage.com',
      },
      {
        label: 'Branding & Resource',
        href: '/brand',
      },
      {
        label: 'Tools & Listings',
        href: '/awesome',
      },
    ],
  },
]

export default MenuConfig
