import React from 'react'
import styled from 'styled-components'
import { Flex } from 'packages/uikit'
import Page from 'components/layout/Page'
import { SectionProps } from './types'
import IntersectionComponent from './IntersectionComponent'

const BackgroundColorWrapper = styled(Flex) <SectionProps>`
  position: relative;
  flex-direction: column;
  z-index: ${({ index }) => index - 1};
  ${({ backgroundImage }) => (!backgroundImage ? null : (`
    background-image: url('${backgroundImage}');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 100%;
  `))};
padding: ${({ noIntersection }) => (noIntersection ? '96px 0 24px 0' : '48px 0;')};
margin: ${({ intersectionPosition }) => (intersectionPosition === 'top' ? '0' : '-34px')} 0;
height: 100vh;

${({ theme }) => theme.mediaQueries.sm} {
  margin: ${({ intersectionPosition }) => (intersectionPosition === 'top' ? '0' : '-42px')} 0;
}

${({ theme }) => theme.mediaQueries.xl} {
  margin: ${({ intersectionPosition }) => (intersectionPosition === 'top' ? '0' : '-52px')} 0;
}

@media screen and(min - width: 1920px) {
  margin: ${({ intersectionPosition }) => (intersectionPosition === 'top' ? '0' : '-72px')} 0;
}
`

const ChildrenWrapper = styled(Page)`
min-height: auto;
padding-top: 16px;
padding-bottom: 16px;

${({ theme }) => theme.mediaQueries.sm} {
  padding-top: 24px;
  padding-bottom: 24px;
}

${({ theme }) => theme.mediaQueries.lg} {
  padding-top: 32px;
  padding-bottom: 32px;
}
`

const Section: React.FC<SectionProps> = ({
  children,
  backgroundImage,
  index = 1,
  intersectComponent,
  intersectionPosition = 'bottom',
  noIntersection = false,
}) => {
  return (
    <>
      {!noIntersection && intersectionPosition === 'top' && (
        <IntersectionComponent
          index={index}
          intersectionPosition={intersectionPosition}
          intersectComponent={intersectComponent}
        />
      )}
      <BackgroundColorWrapper
        backgroundImage={backgroundImage}
        index={index}
        intersectionPosition={intersectionPosition}
        intersectComponent={intersectComponent}
        noIntersection={noIntersection}
      >
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </BackgroundColorWrapper>
      {!noIntersection && intersectionPosition === 'bottom' && (
        <IntersectionComponent
          index={index}
          intersectionPosition={intersectionPosition}
          intersectComponent={intersectComponent}
        />
      )}
    </>
  )
}

export default Section
