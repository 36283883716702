export const DARKBG = 'radial-gradient(329.58% 50% at 50% 50%, #3B2864 0%, #191326 100%)'
export const DARKFILL = '#191326'
export const MIDBLUEBG = 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)'
export const MIDBLUEBG_DARK = 'linear-gradient(180deg, #000000 0%, #540410 95%)'
export const MIDBLUEFILL = '#000000'
export const MIDBLUEFILL_DARK = '#540410'
export const LIGHTBLUEBG = 'linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)'
export const LIGHTBLUEBG_DARK = 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)'
export const LIGHTBLUEFILL = '#F3EFFF'
export const LIGHTBLUEFILL_DARK = '#3D2A54'
export const GOLDGRADIENT = 'linear-gradient(180deg, #FFD800 0%, #EB8C00 100%)'
