import React from 'react'
import { UnicornProps } from './types'
import './style.css';

const Unicorn: React.FC<UnicornProps> = () => {
  return (
    <>
      <div className="bg">
        <div className="stars">
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
          <div className="star"/>
        </div>
        <div className="planets">
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
          <div className="planet"/>
        </div>
      </div>

      <div className="unicorn-container">
        <div className="unicorn">
          <div className="header">
            <div className="horn">
              <div className="lines-container"/>
            </div>
            <div className="head">
              <div className="face">
                <div className="pink"/>
                <div className="white"/>
              </div>
              <div className="hair">
                <div className="inner-hair"/>
              </div>
            </div>
            <div className="neck"/>
          </div>
          <div className="body">
            <div className="main"/>
            <div className="tail">
              <div className="inner-tail"/>
            </div>
          </div>
          <div className="legs">
            <div className="leg"/>
            <div className="leg"/>
            <div className="leg"/>
            <div className="leg"/>
          </div>
        </div>
      </div>

      <div className="rainbow-container">
        <div className="rainbow"/>
      </div>
    </>
  )
}

export default Unicorn
