import React from 'react'
import Button from '../../../components/Button/Button'
import Flex from '../../../components/Box/Flex'
import { useWalletModal } from '../../WalletModal'
import { Login } from '../../WalletModal/types'
import { connectorLocalStorageKey } from '../../WalletModal/config'

interface Props {
  account?: string
  login: Login
  logout: () => void
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <Flex m="16px 8px">
      {account ? (
        <Flex justifyContent="center" >
          <Button
            scale="sm"
            width="100%"
            variant="tertiary"
            mr="7px"
            onClick={() => {
              onPresentAccountModal()
            }}
          >
            {accountEllipsis}
          </Button>
          <Button
            scale="sm"
            variant="secondary"
            onClick={() => {
              logout()
              window.localStorage.removeItem(connectorLocalStorageKey)
            }}
          >
            Logout
          </Button>
        </Flex>
      ) : (
        <Button
          scale="sm"
          width="100%"
          onClick={() => {
            onPresentConnectModal()
          }}
        >
          Connect
        </Button>
      )}
    </Flex>
  )
}

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account)
