import React from 'react'
import styled from 'styled-components'
import PanelBody from './PanelBody'
import PanelFooter from './PanelFooter'
import { PanelProps, PushedProps } from '../types'

interface Props extends PanelProps, PushedProps {
  showMenu: boolean
  isMobile: boolean
}

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu } = props
  return (
    <>
      <PanelBody {...props} />
      <PanelFooter {...props} />
    </>
  )
}

export default Panel
