import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { Route, useRouteMatch, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Toggle, Text, BaseLayout, Unicorn, Box } from 'packages/uikit'
import styled from 'styled-components'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import { useFarms, usePriceCakeBusd, useGetApiPrices } from 'state/hooks'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmUserDataAsync } from 'state/actions'
import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getFarmApr } from 'utils/apr'
import { orderBy } from 'lodash'
import { getAddress } from 'utils/addressHelpers'
import isArchivedPid from 'utils/farmHelpers'
import { latinise } from 'utils/latinise'
import PageHeader from 'components/PageHeader'
import Section from 'components/Section'
import Ribbon from 'components/Ribbon'
import Select, { OptionProps } from 'components/Select/Select'
import { fetchFarmsPublicDataAsync, setLoadArchivedFarmsData } from 'state/farms'
import { PrizesIcon, RanksIcon, RulesIcon } from '../../components/Ribbon/svgs'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'
import FarmHistoryTabButtons from './components/FarmHistoryTabButtons'
import SearchInput from './components/SearchInput'
import Rules from './components/Rules'
import FarmInfoDescriptionCard from './components/FarmInfo/FarmInfoDescriptionCard'
import FarmInfoStepsCard from './components/FarmInfo/FarmInfoStepsCard'
import FarmsStatsCard from './components/FarmInfo/FarmsStatsCard'
import ZeroBitStats from './components/FarmInfo/ZeroBitStats'
import BitLtdaStats from './components/FarmInfo/BitLtdaStats'
import FarmBanner from './components/FarmBanner'

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 22px 0;
    margin-bottom: 0;
  }
`

const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 32px;

  & > div {
    grid-column: span 12;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
    }
  }
`

const CTACards = styled(BaseLayout)`
  align-items: start;
  margin-bottom: 32px;

  & > div {
    grid-column: span 12;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 12;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    & > div {
      grid-column: span 4;
    }
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`

const NUMBER_OF_FARMS_VISIBLE = 12

const Farms: React.FC = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { data: farmsLP } = useFarms()
  const cakePrice = usePriceCakeBusd()
  const [query, setQuery] = useState('')
  const { account } = useWeb3React()
  const [sortOption, setSortOption] = useState('hot')
  const prices = useGetApiPrices()
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchFarmUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const isArchived = pathname.includes('archived')
  const isInactive = pathname.includes('history')
  const isActive = !isInactive && !isArchived

  const [stakedOnly, setStakedOnly] = useState(!isActive)
  useEffect(() => {
    setStakedOnly(!isActive)
  }, [isActive])

  useEffect(() => {
    // Makes the main scheduled fetching to request archived farms data
    dispatch(setLoadArchivedFarmsData(isArchived))

    // Immediately request data for archived farms so users don't have to wait
    // 60 seconds for public data and 10 seconds for user data
    if (isArchived) {
      dispatch(fetchFarmsPublicDataAsync())
      if (account) {
        dispatch(fetchFarmUserDataAsync(account))
      }
    }
  }, [isArchived, dispatch, account])

  const activeFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier > 0 && !isArchivedPid(farm.pid))
  const inactiveFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier === 0 && !isArchivedPid(farm.pid))
  const archivedFarms = farmsLP.filter((farm) => isArchivedPid(farm.pid))

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms = archivedFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const farmsList = useCallback(
    (farmsToDisplay: Farm[]): FarmWithStakedValue[] => {
      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        if (!farm.lpTotalInQuoteToken || !prices) {
          return farm
        }

        const quoteTokenPriceUsd = prices[getAddress(farm.quoteToken.address).toLowerCase()]
        const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPriceUsd)
        const apr = isActive ? getFarmApr(farm.poolWeight, cakePrice, totalLiquidity) : 0

        return { ...farm, apr, liquidity: totalLiquidity }
      })

      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return farmsToDisplayWithAPR
    },
    [cakePrice, prices, query, isActive],
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const loadMoreRef = useRef<HTMLDivElement>(null)

  const [numberOfFarmsVisible, setNumberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)

  const farmsStakedMemoized = useMemo(() => {
    let farmsStaked = []

    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'apr':
          return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr, 'desc')
        case 'multiplier':
          return orderBy(farms, (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier) : 0), 'desc')
        case 'earned':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case 'liquidity':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        case 'depositFee':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.depositFee), 'desc')
        case 'harvestLookup':
          return orderBy(farms, (farm: FarmWithStakedValue) => new BigNumber(farm.harvestLookup), 'asc')
        case 'minimumEarnedAmount':
          return orderBy(farms, (farm: FarmWithStakedValue) => new BigNumber(farm.minimumEarnedAmount), 'asc')
        case 'amountRequired':
          return orderBy(farms, (farm: FarmWithStakedValue) => new BigNumber(farm.amountRequired), 'asc')
        case 'rarity':
          return orderBy(farms, (farm: FarmWithStakedValue) => farm.rarity, 'desc')
        default:
          return farms
      }
    }

    if (isActive) {
      farmsStaked = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    }
    if (isInactive) {
      farmsStaked = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
    }
    if (isArchived) {
      farmsStaked = stakedOnly ? farmsList(stakedArchivedFarms) : farmsList(archivedFarms)
    }

    return sortFarms(farmsStaked).slice(0, numberOfFarmsVisible)
  }, [
    sortOption,
    activeFarms,
    farmsList,
    inactiveFarms,
    archivedFarms,
    isActive,
    isInactive,
    isArchived,
    stakedArchivedFarms,
    stakedInactiveFarms,
    stakedOnly,
    stakedOnlyFarms,
    numberOfFarmsVisible,
  ])

  useEffect(() => {
    const showMoreFarms = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfFarmsVisible((farmsCurrentlyVisible) => farmsCurrentlyVisible + NUMBER_OF_FARMS_VISIBLE)
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMoreFarms, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [farmsStakedMemoized, observerIsSet])

  const renderContent = (): JSX.Element => {
    return (
      <div>
        <FlexLayout>
          <Route exact path={`${path}`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/core`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/startup`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/sports`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/defi`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/yield-farm`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/community`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/history`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed />
            ))}
          </Route>
          <Route exact path={`${path}/archived`}>
            {farmsStakedMemoized.map((farm) => (
              <FarmCard key={farm.pid} farm={farm} account={account} removed />
            ))}
          </Route>
        </FlexLayout>
      </div>
    )
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  return (
    <>
      <Section
        backgroundImage='images/bg/bg-farms.png'
        index={4}
        intersectComponent={<Ribbon ribbonDirection="up">Farm Fields</Ribbon>}
      >
        <Box mt={['94px', null, '36px']} mb={['24px', null, '0']}>
          <FarmBanner />
        </Box>
      </Section>
      {/* 
      <PageHeader>
        <Heading as="h1" scale="xxl" mb="12px">
          {t('Farms')}
        </Heading>
        <Heading scale="lg" color="text" mb="48px">
          {t('Stake Liquidity Pool (LP) tokens to earn.')}
        </Heading>
        
      </PageHeader>
      */}
      <Section index={4} intersectComponent={<Ribbon ribbonDirection="up">What and Info</Ribbon>}>
        <ControlContainer>
          <FilterContainer>
            <LabelWrapper style={{ marginRight: 16 }}>
              <Text>{t('Search')}</Text>
              <SearchInput onChange={handleChangeQuery} />
            </LabelWrapper>
            <LabelWrapper style={{ marginRight: 16 }}>
              <Text>{t('Category')}</Text>
              <Select
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                  {
                    label: 'Core',
                    value: 'core',
                  },
                  {
                    label: 'Startup',
                    value: 'startup',
                  },
                  {
                    label: 'Sports',
                    value: 'sports',
                  },
                  {
                    label: 'DeFi',
                    value: 'defi',
                  },
                  {
                    label: 'Yield Farm',
                    value: 'yieldFarm',
                  },
                  {
                    label: 'Community',
                    value: 'community',
                  },
                ]}
                onChange={handleSortOptionChange}
              />
            </LabelWrapper>
            <LabelWrapper>
              <Text>{t('Sort By')}</Text>
              <Select
                options={[
                  {
                    label: 'Hot',
                    value: 'hot',
                  },
                  {
                    label: 'APR',
                    value: 'apr',
                  },
                  {
                    label: 'Rarity',
                    value: 'rarity',
                  },
                  {
                    label: 'Multiplier',
                    value: 'multiplier',
                  },
                  {
                    label: 'Earned',
                    value: 'earned',
                  },
                  {
                    label: 'Liquidity',
                    value: 'liquidity',
                  },
                  {
                    label: 'Deposit Fee',
                    value: 'depositFee',
                  },
                  {
                    label: 'Harvest Lookup',
                    value: 'harvestLookup',
                  },
                  {
                    label: 'Minimum Earned',
                    value: 'minimumEarnedAmount',
                  },
                  {
                    label: 'TMA Required',
                    value: 'amountRequired',
                  },
                ]}
                onChange={handleSortOptionChange}
              />
            </LabelWrapper>
            {(process.env.NODE_ENV === 'development' || stakedInactiveFarms.length > 0) && (
              <FarmHistoryTabButtons hasStakeInFinishedFarms={stakedInactiveFarms.length > 0} />
            )}
          </FilterContainer>
          <ViewControls>
            <ToggleWrapper>
              <Toggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
              <Text> {t('Staked only')}</Text>
            </ToggleWrapper>
          </ViewControls>
        </ControlContainer>
        {renderContent()}
        <div ref={loadMoreRef} />
      </Section>
      <Section index={4} intersectComponent={<Ribbon ribbonDirection="up">FAQ</Ribbon>}>
        <Box mt={['94px', null, '36px']} mb={['24px', null, '0']}>
          <Cards>
            <FarmInfoDescriptionCard />
            <FarmInfoStepsCard />
          </Cards>
          <CTACards>
            <FarmsStatsCard farms={farmsList(activeFarms)} />
            <ZeroBitStats />
            {true ? <BitLtdaStats /> : <BitLtdaStats />}
          </CTACards>
        </Box>
      </Section>
      <Section index={3}>
        <Rules />
      </Section>
      <Page>
        <Unicorn />
      </Page>
    </>
  )
}

export default Farms
