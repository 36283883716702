import React from 'react'
import styled from 'styled-components'
import { Heading, BaseLayout, Unicorn, Alert, Box } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'
import PageHeader from 'components/PageHeader'
import Page from 'components/layout/Page'
import Section from 'components/Section'
import Ribbon from 'components/Ribbon'
import FarmStakingCard from 'views/Dungeon/components/FarmStakingCard'
import ZeroBitStats from 'views/Dungeon/components/ZeroBitStats'
import BitLtdaStats from 'views/Dungeon/components/BitLtdaStats'
import TotalValueLockedCard from 'views/Dungeon/components/TotalValueLockedCard'
import EarnAPRCard from 'views/Dungeon/components/EarnAPRCard'
import EarnAssetCard from 'views/Dungeon/components/EarnAssetCard'
import TwitterCard from 'views/Dungeon/components/TwitterCard'
import CountDown from './components/CountDown'
import FarmBanner from './components/FarmBanner'

const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 32px;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
    }
  }
`

const CTACards = styled(BaseLayout)`
  align-items: start;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 4;
    }
  }
`

const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Section
        backgroundImage='images/bg/bg-vault.png'
        index={4}
        intersectComponent={<Ribbon ribbonDirection="up">Farm Fields</Ribbon>}
      >
        <Box mt={['94px', null, '36px']} mb={['24px', null, '0']}>
          <FarmBanner />
        </Box>
      </Section>
      {/* <PageHeader>
        <Heading as="h1" scale="xxl" mb="12px">
          {t('Welcome')}
        </Heading>
        <Heading scale="lg" color="text" mb="16px">
          {t('Next-gen Startup Founding & Fundraising')}
        </Heading>
          <Alert variant="info" title="The Medieval Age Farms launch (20th June, 2021)">
            <Heading scale="xxl">
              <CountDown />
            </Heading>
          </Alert>
      </PageHeader> */}
      <Page>
        <div>
          <Cards>
            <FarmStakingCard />
            <CTACards>
              <EarnAPRCard />
              <EarnAssetCard />
              <TotalValueLockedCard />
            </CTACards>
          </Cards>
          <Cards>
            <CTACards>
              <ZeroBitStats />
              <BitLtdaStats />
            </CTACards>
            <TwitterCard />
          </Cards>
        </div>
        <Unicorn />
      </Page>
    </>
  )
}

export default Home
