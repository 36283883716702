import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, Heading, Text } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'
import { useGetStats } from 'hooks/api'
import CardValue from './CardValue'

const StyledFarmsStatsCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 300px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin: 0;
    max-width: none;
  }
`

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const RowCenter = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
`

const FarmsStatsCard = ({ farms }) => {
  const { t } = useTranslation()
  const data = useGetStats()
  const tvl = data ? data.total_value_locked_all : Number(1000000000)

  let totalApr = 0.0
  let totalLiquidity = 0

  farms.forEach((farm) => {
    totalApr += farm.apr
    totalLiquidity += Number(farm.liquidity)
  })

  return (
    <>
      <StyledFarmsStatsCard isSuccess>
        <CardBody p="24px 24px 0 24px">
          <Heading scale="lg" mb="16px">
            {t('Total Value Locked (TVL)')}
          </Heading>
          <RowCenter>
            {tvl && <CardValue color="success" bold fontSize="20px" decimals={2} prefix="$" value={tvl} />}
          </RowCenter>
        </CardBody>
        <CardBody>
          <Heading scale="lg" mb="16px">
            {t('Farms Stats')}
          </Heading>
          <Row>
            <Text fontSize="16px">{t('Active Farms')}</Text>
            <CardValue fontSize="16px" decimals={0} value={farms.length} />
          </Row>
          <Row>
            <Text fontSize="16px">{t('Upcoming Farms')}</Text>
            <CardValue fontSize="16px" decimals={0} value={farms.length} />
          </Row>
          <Row>
            <Text fontSize="16px">{t('Total APR')}</Text>
            {totalApr && <CardValue fontSize="16px" decimals={2} sufix="%" value={totalApr} />}
          </Row>
          <Row>
            <Text fontSize="16px">{t('Total Liquidity')}</Text>
            {totalLiquidity && <CardValue fontSize="16px" decimals={0} prefix="$" value={totalLiquidity} />}
          </Row>
        </CardBody>
      </StyledFarmsStatsCard>
    </>
  )
}

export default FarmsStatsCard
