import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '28px',
    padding: '0 8px',
    fontSize: '14px',
  },
  [scales.SM]: {
    height: '24px',
    padding: '0 4px',
    fontSize: '12px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: 'primary',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'secondary',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
  },
  [variants.WARNING]: {
    backgroundColor: 'warning',
  },
  [variants.FAILURE]: {
    backgroundColor: 'failure',
  },
  [variants.ANCIENT]: {
    backgroundColor: 'ancient',
  },
  [variants.LEGENDARY]: {
    backgroundColor: 'legendary',
  },
  [variants.MYTHICAL]: {
    backgroundColor: 'mythical',
  },
  [variants.RARE]: {
    backgroundColor: 'rare',
  },
  [variants.UNCOMMON]: {
    backgroundColor: 'uncommon',
  },
  [variants.COMMOON]: {
    backgroundColor: 'common',
  },
  [variants.TEXTDISABLED]: {
    backgroundColor: 'textDisabled',
  },
  [variants.TEXTSUBTLE]: {
    backgroundColor: 'textSubtle',
  },
  [variants.BINANCE]: {
    backgroundColor: 'binance',
  },
  [variants.RAINBOW]: {
    backgroundColor: 'gradients.rainbow',
    borderImage: 'linear-gradient(240deg, rgba(255,0,0,0.7) 0%, rgba(255,154,0,0.7) 10%, rgba(208,222,33,0.7) 20%, rgba(79,220,74,0.7) 30%, rgba(63,218,216,0.7) 40%, rgba(47,201,226,0.7) 50%, rgba(28,127,238,0.7) 60%, rgba(95,21,242,0.7) 70%, rgba(186,12,248,0.7) 80%, rgba(251,7,217,0.7) 90%, rgba(255,0,0,0.7) 100% )',
    backgroundSize: '600% 600%',
    animation: `gradient 20s ease infinite`,
    '@keyframes gradient': {'0%': {backgroundPosition: '0% 50%'}, '50%': {backgroundPosition: '100% 100%'}, '100%': {backgroundPosition: '0% 50%'}},
  },
}
