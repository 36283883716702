import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, CardHeader, Heading, Text, Flex } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'
import FoldableText from 'components/FoldableText'

const Wrapper = styled(Flex)`
  margin-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex: 1;
    margin-top: 0px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    flex: 2;
  }
`

const StyledCardbody = styled(CardBody)`
  div:first-child {
    margin-top: 0px;
  }
`

const FAQ = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Card>
        <CardHeader>
          <Heading scale="lg">{t('Frequently Asked Questions')}</Heading>
        </CardHeader>
        <StyledCardbody>
          <FoldableText title={t('Vestibulum tincidunt gravida est')} mt="24px">
            <Text fontSize="14px" color="textSubtle">
              {t(
                'Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi.',
              )}
            </Text>
          </FoldableText>
          <FoldableText title={t('Vestibulum tincidunt gravida est')} mt="24px">
            <Text fontSize="14px" color="textSubtle">
              {t(
                'Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi.',
              )}
            </Text>
          </FoldableText>
          <FoldableText title={t('Vestibulum tincidunt gravida est')} mt="24px">
            <Text fontSize="14px" color="textSubtle">
              {t(
                'Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi.',
              )}
            </Text>
          </FoldableText>
          <FoldableText title={t('Vestibulum tincidunt gravida est')} mt="24px">
            <Text fontSize="14px" color="textSubtle">
              {t(
                'Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi.',
              )}
            </Text>
          </FoldableText>
          <FoldableText title={t('Vestibulum tincidunt gravida est')} mt="24px">
            <Text fontSize="14px" color="textSubtle">
              {t(
                'Aenean vitae tempor diam. Nam vitae mi urna. Cras gravida sapien venenatis lacinia facilisis. Etiam in diam sed est vehicula viverra eu vel nisi.',
              )}
            </Text>
          </FoldableText>
        </StyledCardbody>
      </Card>
    </Wrapper>
  )
}

export default FAQ
