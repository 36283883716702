import React from 'react'
import { Tag, VerifiedIcon, CommunityIcon, BinanceIcon, RefreshIcon, AutoRenewIcon } from '../packages/uikit'

const CoreTag = (props) => (
  <Tag outline bold variant="common" startIcon={<VerifiedIcon width="18px" mr="4px" />} {...props}>
    Core
  </Tag>
)

const StartupTag = (props) => (
  <Tag outline bold variant="common" startIcon={<CommunityIcon width="18px" mr="4px" />} {...props}>
    Startup
  </Tag>
)

const SportsTag = (props) => (
  <Tag outline bold variant="common" startIcon={<CommunityIcon width="18px" mr="4px" />} {...props}>
    Sports
  </Tag>
)

const DeFiTag = (props) => (
  <Tag outline bold variant="common" startIcon={<CommunityIcon width="18px" mr="4px" />} {...props}>
    DeFi
  </Tag>
)

const YieldFarmTag = (props) => (
  <Tag outline bold variant="common" startIcon={<CommunityIcon width="18px" mr="4px" />} {...props}>
    Yield Farm
  </Tag>
)

const CommunityTag = (props) => (
  <Tag outline bold variant="common" startIcon={<CommunityIcon width="18px" mr="4px" />} {...props}>
    Community
  </Tag>
)

const BinanceTag = (props) => (
  <Tag outline variant="binance" startIcon={<BinanceIcon width="18px" color="secondary" mr="4px" />} {...props}>
    Binance
  </Tag>
)

const DualTag = (props) => (
  <Tag outline variant="textSubtle" {...props}>
    Dual
  </Tag>
)

const ManualPoolTag = (props) => (
  <Tag outline variant="secondary" startIcon={<RefreshIcon width="18px" color="secondary" mr="4px" />} {...props}>
    Manual
  </Tag>
)

const CompoundingPoolTag = (props) => (
  <Tag outline variant="success" startIcon={<AutoRenewIcon width="18px" color="success" mr="4px" />} {...props}>
    Auto
  </Tag>
)

export {
  CoreTag,
  StartupTag,
  SportsTag,
  DeFiTag,
  YieldFarmTag,
  CommunityTag,
  BinanceTag,
  DualTag,
  ManualPoolTag,
  CompoundingPoolTag,
}
