import { DefaultTheme } from 'styled-components'
import darkAlert from '../components/Alert/theme'
import darkCard from '../components/Card/theme'
import darkRadio from '../components/Radio/theme'
import darkToggle from '../components/Toggle/theme'
import darkNav from '../widgets/Menu/theme'
import darkModal from '../widgets/Modal/theme'
import darkTooltip from '../components/Tooltip/theme'
import base from './base'
import { darkColors } from './colors'

const darkTheme: DefaultTheme = {
  ...base,
  alert: darkAlert,
  colors: darkColors,
  card: darkCard,
  toggle: darkToggle,
  nav: darkNav,
  modal: darkModal,
  radio: darkRadio,
  tooltip: darkTooltip,
}

export default darkTheme
