import React from 'react'
import styled, { keyframes, DefaultTheme } from 'styled-components'
import { Text } from '../../../components/Text'
import { Colors } from '../../../theme/types'
import { MENU_ENTRY_HEIGHT } from '../config'

export interface Props {
  secondary?: boolean
  isActive?: boolean
  theme: DefaultTheme
}

const LinkLabel = styled.div<{ isPushed: boolean }>`
  transition: color 0.4s;
  flex-grow: 1;
`

const MenuEntry = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  margin: ${({ secondary }) => (secondary ? '0' : '0 8px')};
  padding: ${({ secondary }) => (secondary ? '0 32px' : '0 16px')};
  font-size: ${({ secondary }) => (secondary ? '16px' : '18px')};
  background-color: ${({ secondary, theme }) => (secondary ? theme.colors.background : 'transparent')};
  color: ${({ theme }) => theme.colors.textSubtle};
  ${({ isActive, theme }) => (isActive ? `border-radius: 15px; background: rgba(0,0,0, 0.2);;` : 'box-shadow: none')};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    margin-right: 12px;
    transform: scale(1.2);
  }
 
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    svg {
      fill: ${({ theme }) => theme.colors.secondary};
      transform: scale(1.6);
    }
  }
  
  // Safari fix
  flex-shrink: 0;
`
MenuEntry.defaultProps = {
  secondary: false,
  isActive: false,
  role: 'button',
}

const LinkStatus = styled(Text)<{ color: keyof Colors }>`
  border-radius: ${({ theme }) => theme.radii.default};
  padding: 0 8px;
  border: 2px solid;
  border-color: ${({ theme, color }) => theme.colors[color]};
  box-shadow: none;
  margin-left: 8px;
`

const LinkLabelMemo = React.memo(LinkLabel, (prev, next) => prev.isPushed === next.isPushed)

export { MenuEntry, LinkStatus, LinkLabelMemo as LinkLabel }
