import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'The Medieval Age',
  description:
    'The medieval era has begun, chapter 1 seasson 1. Start now with your farm and prepare for battle.',
  image: 'https://themedievalage.com/images/hero.png',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Home | The Medieval Age',
  },
  '/farms': {
    title: 'Farms | The Medieval Age',
  },
  '/pools': {
    title: 'Pools | The Medieval Age',
  },
}
