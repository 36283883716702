import React from 'react'
import { Flex, Text, Heading, Image } from 'packages/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import { GOLDGRADIENT } from 'components/Section/sectionStyles'
import { Heading1Text, Heading2Text } from 'components/Ribbon/FarmHeadingText'

const TextStyles = (theme) => `
  text-align: center;
  ${theme.mediaQueries.md} {
    text-align: left;
  }
`

const ImageWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`

const StyledText = styled(Text)`
  ${({ theme }) => TextStyles(theme)}
`

const StyledHeading1Text = styled(Heading1Text)`
  ${({ theme }) => TextStyles(theme)}
`

const StyledHeading2Text = styled(Heading2Text)`
  ${({ theme }) => TextStyles(theme)}
`

const StyledHeading = styled(Heading)`
  ${({ theme }) => TextStyles(theme)}
`

const FarmBanner = () => {
  const { t } = useTranslation()
  return (
    <Flex flexDirection="column">
      {/* <ImageWrapper>
        <Image src={AllBunniesImage} alt="all the bunnies" width={1208} height={659} responsive />
      </ImageWrapper> */}
      <StyledText mb="16px" color="textSubtle" bold>
        {t('Stake & Earn Yield')}
      </StyledText>
      <StyledHeading1Text>{t('Yield Farm')}</StyledHeading1Text>
      <StyledHeading2Text background={GOLDGRADIENT} $fill>
        {t('More then 1M dolars in TVL!')}
      </StyledHeading2Text>
      <StyledHeading scale="md" color="inputSecondary" mt="16px">
        {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus vehicula tellus.')}
      </StyledHeading>
    </Flex>
  )
}

export default FarmBanner
