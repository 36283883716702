import React from 'react'
import styled from 'styled-components'
import { useTooltip, HelpIcon } from '../packages/uikit'

interface TooltipProps {
  description?: string
  iconWidth?: string
  iconColor?: string
}

const ReferenceElement = styled.div`
  display: inline-block;
`
const Tooltip: React.FC<TooltipProps> = (props) => {
  const { description, iconWidth, iconColor } = { ...props }

  const { targetRef, tooltip, tooltipVisible } = useTooltip(description, {
    placement: 'top-end',
    tooltipOffset: [20, 10],
  })

  return (
    <>
      <ReferenceElement ref={targetRef}>
        <HelpIcon width={iconWidth} color={iconColor} ml="4px" />
      </ReferenceElement>
      {tooltipVisible && tooltip}
    </>
  )
}

Tooltip.defaultProps = {
  description: 'Lorem upslum',
  iconWidth: '16px',
  iconColor: 'secondary',
}

export default Tooltip
export { Tooltip }
