import React from 'react'
import { Button, Card, CardBody, Heading, Text, MetamaskIcon, BSCScanIcon } from 'packages/uikit'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { registerToken } from 'utils/wallet'
import { useTotalSupply, useBurnedBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { getCakeAddress } from 'utils/addressHelpers'
import { usePriceCakeBusd } from 'state/hooks'

import CardValue from './CardValue'

const StyledBitLtdaStats = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 300px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin: 0;
    max-width: none;
  }
`

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const BitLtdaStats = () => {
  const { t } = useTranslation()
  const totalSupply = useTotalSupply()
  const eggPrice = usePriceCakeBusd()
  const cakeContractAddress = getCakeAddress()
  const burnedBalance = useBurnedBalance(cakeContractAddress)
  const cakeSupply = totalSupply ? totalSupply.minus(burnedBalance) : 0
  const marketCap = eggPrice.times(cakeSupply)

  return (
    <StyledBitLtdaStats isSuccess>
      <CardBody>
        <Heading scale="lg" mb="24px">
          {t('The Medieval Age Stats')}
        </Heading>
        <Row>
          <Text fontSize="16px">{t('The Medieval Age Market Cap')}</Text>
          {marketCap && <CardValue fontSize="16px" decimals={0} prefix="$" value={getBalanceNumber(marketCap)} />}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Total Minted')}</Text>
          {totalSupply && (
            <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={getBalanceNumber(totalSupply)} />
          )}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Total Burned')}</Text>
          {burnedBalance && (
            <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={getBalanceNumber(burnedBalance)} />
          )}
        </Row>
        <Row>
          <Text fontSize="16px">{t('Circulating Supply')}</Text>
          {cakeSupply && <CardValue fontSize="16px" sufix=" TMA" value={getBalanceNumber(cakeSupply)} />}
        </Row>
        <Row>
          <Text fontSize="16px">{t('New The Medieval Age/block')}</Text>
          <CardValue fontSize="16px" decimals={0} sufix=" TMA" value={20} />
        </Row>
        <Row>
          <Button
            mt="15px"
            scale="sm"
            width="13%"
            onClick={() => registerToken(cakeContractAddress, 'The Medieval Age', 18, 'noimgyet')}
          >
            <MetamaskIcon />
          </Button>
          <Button
            as="a"
            external
            ml="8px"
            mt="15px"
            scale="sm"
            width="36%"
            href={`https://bscscan.com/token/${cakeContractAddress}`}
            startIcon={<BSCScanIcon />}
          >
            {t('BSCScan')}
          </Button>
          <Button
            as="a"
            ml="8px"
            external
            mt="15px"
            scale="sm"
            width="49%"
            href={`https://exchange.themedievalage.com/#/swap?outputCurrency=${cakeContractAddress}`}
          >
            {t('Buy TMA')}
          </Button>
        </Row>
      </CardBody>
    </StyledBitLtdaStats>
  )
}

export default BitLtdaStats
