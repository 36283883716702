import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '48px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    color: 'primary',
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'primary',
    opacity: '0.7',
    '&:hover': {
      color: 'common',
      background: 'linear-gradient(240deg, rgba(255,0,0,0.7) 0%, rgba(255,154,0,0.7) 10%, rgba(208,222,33,0.7) 20%, rgba(79,220,74,0.7) 30%, rgba(63,218,216,0.7) 40%, rgba(47,201,226,0.7) 50%, rgba(28,127,238,0.7) 60%, rgba(95,21,242,0.7) 70%, rgba(186,12,248,0.7) 80%, rgba(251,7,217,0.7) 90%, rgba(255,0,0,0.7) 100% )',
      backgroundSize: '600% 600%',
    animation: `gradient 20s ease infinite`,
    '@keyframes gradient': {'0%': {backgroundPosition: '0% 50%'}, '50%': {backgroundPosition: '100% 100%'}, '100%': {backgroundPosition: '0% 50%'}},
      borderColor: 'secondary',
      opacity: '1',
    },
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'primary',
    boxShadow: 'none',
    color: 'primary',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: 'primary',
  },
  [variants.SUBTLE]: {
    backgroundColor: 'textSubtle',
    color: 'white',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
    '&:hover': {
      opacity: '1',
      textDecoration: 'underline',
    },
  },
}
