import { LinkStatus } from './types'

export const status = {
  LIVE: <LinkStatus>{
    text: 'LIVE',
    color: 'failure',
  },
  SOON: <LinkStatus>{
    text: 'SOON',
    color: 'warning',
  },
  NEW: <LinkStatus>{
    text: 'NEW',
    color: 'success',
  },
}

export const links = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.themedievalage.com',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.themedievalage.com/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
    status: status.LIVE,
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/syrup',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: '/nft',
  },
  {
    label: 'Team Battle',
    icon: 'TeamBattleIcon',
    href: '/competition',
    status: status.SOON,
  },
  {
    label: 'Profile & Teams',
    icon: 'GroupsIcon',
    status: status.LIVE,
    items: [
      {
        label: 'Leaderboard',
        href: '/teams',
        status: status.NEW,
      },
      {
        label: 'YourProfile',
        href: '/',
      },
    ],
    calloutClass: 'rainbow',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: 'https://pancakeswap.info',
      },
      {
        label: 'Tokens',
        href: 'https://pancakeswap.info/tokens',
      },
      {
        label: 'Pairs',
        href: 'https://pancakeswap.info/pairs',
      },
      {
        label: 'Accounts',
        href: 'https://pancakeswap.info/accounts',
      },
    ],
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    items: [
      {
        label: 'Next',
        href: '/ifo',
      },
      {
        label: 'History',
        href: '/ifo/history',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Voting',
        href: 'https://voting.themedievalage.com',
      },
      {
        label: 'Github',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: 'Docs',
        href: 'https://docs.themedievalage.com',
      },
      {
        label: 'Blog',
        href: 'https://themedievalage.medium.com',
      },
    ],
  },
]

export const socials = [
  {
    label: 'GitHub',
    icon: 'GitHubIcon',
    href: 'https://github.com/bitLTDA',
  },
  {
    label: 'Docs',
    icon: 'GitBookIcon',
    href: 'https://docs.themedievalage.com',
  },
  {
    label: 'Medium',
    icon: 'MediumIcon',
    href: 'https://themedievalage.medium.com',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    items: [
      {
        label: 'English',
        href: 'https://t.me/themedievalageEN',
      },
      {
        label: 'Deutsche',
        href: 'https://t.me/themedievalageDE',
      },
      {
        label: 'Bahasa Indonesia',
        href: 'https://t.me/themedievalageID',
      },
      {
        label: '中文',
        href: 'https://t.me/themedievalageCN',
      },
      {
        label: 'Tiếng Việt',
        href: 'https://t.me/themedievalageVN',
      },
      {
        label: 'Italiano',
        href: 'https://t.me/themedievalageIT',
      },
      {
        label: 'русский',
        href: 'https://t.me/themedievalageRU',
      },
      {
        label: 'Türkiye',
        href: 'https://t.me/themedievalageTR',
      },
      {
        label: 'Português',
        href: 'https://t.me/themedievalagePT',
      },
      {
        label: 'Español',
        href: 'https://t.me/themedievalageES',
      },
      {
        label: '日本語',
        href: 'https://t.me/themedievalageJP',
      },
      {
        label: 'Français',
        href: 'https://t.me/themedievalageFR',
      },
      {
        label: 'Announcements',
        href: 'https://t.me/themedievalageAnn',
      },
      {
        label: 'Help & Support',
        href: 'https://t.me/themedievalage',
      },
      {
        label: 'Info Bot',
        href: 'https://t.me/themedievalageBOT',
      },
    ],
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/bitLTDA',
  },
]

export const MENU_HEIGHT = 48
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
