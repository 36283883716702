import React from 'react'
import { LoadingProps } from './types'
import './style.css'

const Loading: React.FC<LoadingProps> = () => {
  return (
    <>
      <div className="unicorn-container">
        <div className="unicorn">
          <div className="header">
            <div className="horn">
              <div className="lines-container" />
            </div>
            <div className="head">
              <div className="face">
                <div className="pink" />
                <div className="white" />
              </div>
              <div className="hair">
                <div className="inner-hair" />
              </div>
            </div>
            <div className="neck" />
          </div>
          <div className="body">
            <div className="main" />
            <div className="tail">
              <div className="inner-tail" />
            </div>
          </div>
          <div className="legs">
            <div className="leg" />
            <div className="leg" />
            <div className="leg" />
            <div className="leg" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Loading
