import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import BigNumber from 'bignumber.js'
import {
  TabMenu,
  Tab,
  LinkExternal,
  Button,
  Flex,
  Text,
  Skeleton,
  Heading,
  Image,
  Tag,
  BSCScanIcon,
  VerifiedIcon,
} from 'packages/uikit'
import { Farm } from 'state/types'
import { provider as ProviderType } from 'web3-core'
import { useTranslation } from 'contexts/Localization'
import { registerToken } from 'utils/wallet'
import { getAddress } from 'utils/addressHelpers'
import { useGetApiPrices } from 'state/hooks'
import { BASE_ADD_LIQUIDITY_URL } from 'config'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import { CoreTag, StartupTag, SportsTag, DeFiTag, YieldFarmTag, CommunityTag } from 'components/Tags'
import ExpandableSectionButton from 'components/ExpandableSectionButton'
import Timer from 'components/Timer'
import { Tooltip } from 'components/Tooltips'
import getTimePeriods from 'utils/getTimePeriods'
import CardActionsContainer from './CardActionsContainer'

export interface FarmWithStakedValue extends Farm {
  apr?: number
  liquidity?: BigNumber
  depositFee?: number
  harvestLookup?: number
  minimumEarnedAmount?: BigNumber
  amountRequired?: BigNumber
}

const fadeOut = keyframes`
	 0% { opacity: 1;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0;width: 0; height: 0;}
`

const RainbowLight = keyframes`
	0% {
		background-position: 0% 0%;
    filter: blur(8px);
	}
	25% {
		background-position: 100% 100%;
    filter: blur(8px);
	}
  50% {
		background-position: 50% 0%;
    filter: blur(11px);
	}
  50% {
		background-position: 50% 0%;
    filter: blur(8px);
	}
	100% {
		background-position: 0% 0%;
    filter: blur(9px);
	}
`

const RarityLight = keyframes`
  from {
    filter: blur(5px);
  }
	25%{
    filter: blur(5px);
	}
  50% {
    filter: blur(7px);
	}
  75% {
    filter: blur(9px);
	}
	to {
    filter: blur(6px);
	}
`

const holoSparkle = (variant) => keyframes`
  0%, 5%, 100% { 
    ${variant === 'ancient' && 'opacity: .30;'}
    ${variant === 'legendary' && 'opacity: .20;'}
    ${variant === 'mythical' && 'opacity: .10;'}
    ${variant === 'rare' && 'opacity: .05;'}
    background-position: 50% 50%; }
  33% { 
    opacity: 0.1;
    background-position: 40% 40%; filter: brightness(2); }
  45% { 
    
    ${variant === 'ancient' && 'opacity: .30;'}
    ${variant === 'legendary' && 'opacity: .20;'}
    ${variant === 'mythical' && 'opacity: .10;'}
    ${variant === 'rare' && 'opacity: .05;'}
  }
  66% {
    opacity: 0.25;
    background-position: 60% 60%; filter: brightness(2);
  }
`

const holoGradient = (variant) => keyframes`
   0%, 100% {
    ${variant === 'ancient' && 'opacity: .35;'}
    ${variant === 'legendary' && 'opacity: .30;'}
    ${variant === 'mythical' && 'opacity: .25;'}
    ${variant === 'rare' && 'opacity: .20;'}
    ${variant === 'uncommon' && 'opacity: .15;'}
    ${variant === 'common' && 'opacity: .15;'}
    background-position: 50% 50%;
  }
  33% {
    background-position: 100% 100%;
    ${variant === 'ancient' && 'opacity: .60;'}
    ${variant === 'legendary' && 'opacity: .55;'}
    ${variant === 'mythical' && 'opacity: .44;'}
    ${variant === 'rare' && 'opacity: .33;'}
    ${variant === 'uncommon' && 'opacity: .15;'}
    ${variant === 'common' && 'opacity: .15;'}
  }
  66% {
    background-position: 0% 0%;
    ${variant === 'ancient' && 'opacity: .33;'}
    ${variant === 'legendary' && 'opacity: .25;'}
    ${variant === 'mythical' && 'opacity: .22;'}
    ${variant === 'rare' && 'opacity: .15;'}
    ${variant === 'uncommon' && 'opacity: .07;'}
    ${variant === 'common' && 'opacity: .07;'}
  }
`

const StyledHoloCard = styled.div<{ variant: string }>`
  padding: 16px;
  margin-bottom: 16px;
  --color1: ${({ variant, theme }) => (variant ? theme.colors[variant] : 'rgb(0, 231, 255)')};
  --color2: ${({ variant, theme }) => (variant ? theme.colors[variant] : 'rgb(255, 0, 231)')};
  width: 100%;
  height: 100%;
  background-image: url(/images/card-bg.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 25px;
  transform-origin: center;
  z-index: 10;
  overflow: hidden;
  transform: translate3d(0, 0, -1px);
  &:after,
  &:before {
    content: '';
    opacity: 0.1;
    mix-blend-mode: screen;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
  }
  &:before {
    background-position: 50% 50%;
    background-size: 300% 300%;
    -webkit-animation: ${({ variant }) => holoGradient(variant)} 35s ease infinite both;
    animation: ${({ variant }) => holoGradient(variant)} 35s ease infinite both;
    background-image: linear-gradient(
      115deg,
      transparent 0%,
      var(--color1) 30%,
      transparent 47.5%,
      transparent 52.5%,
      var(--color2) 70%,
      transparent 100%
    );
  }
  &:after {
    opacity: 1;
    ${({ variant }) =>
      variant !== 'common' &&
      variant !== 'uncommon' &&
      "background-image: url('https://assets.codepen.io/13471/sparkles.gif');"}
    background-position: center;
    background-size: 160%;
    z-index: 2;
    -webkit-animation: ${({ variant }) => holoSparkle(variant)} 15s ease infinite both;
    animation: ${({ variant }) => holoSparkle(variant)} 15s ease infinite both;
    filter: brightness(1);
    transition: filter 0.5s ease;
  }
  &:hover {
    animation: none;
    box-shadow: -13px -13px 13px -15px var(--color1), 13px 13px 13px -15px var(--color2),
      0 0 4px 2px rgba(255, 255, 255, 0.5), 0 35px 25px -15px rgba(0, 0, 0, 0.3);
    &:after {
      filter: opacity(0.3);
      transition: filter 1s ease;
      animation: ${fadeOut} 1s;
      animation-fill-mode: forwards;
    }
    &:before {
      filter: opacity(0.3);
      transition: filter 3s ease;
    }
  }
`

const StyledCardRarity = styled.div<{ variant: string }>`
  background: ${({ variant, theme }) => theme.colors[variant]};
  animation: ${RarityLight} 4s linear infinite;
  border-radius: 10px;
  position: absolute;
  top: ${({ variant }) => (variant === 'ancient' ? '-4px' : '-2px')};
  right: ${({ variant }) => (variant === 'ancient' ? '-4px' : '-2px')};
  bottom: ${({ variant }) => (variant === 'ancient' ? '-4px' : '-2px')};
  left: ${({ variant }) => (variant === 'ancient' ? '-4px' : '-2px')};
  z-index: -1;
`

const FCard = styled.div<{ variant: string }>`
  align-self: baseline;
  background: ${({ theme }) => theme.card.background};
  border: 1px solid ${({ variant, theme }) => (variant === 'ancient' ? 'transparent' : theme.colors[variant])};
  border-radius: 16px;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 12px;
  position: relative;
`

const StyledHeading = styled(Heading)<{ variant: string }>`
  color: ${({ variant, theme }) => theme.colors[variant]};
`

const Divider = styled.div<{ variant: string }>`
  background-color: ${({ variant, theme }) => theme.colors[variant]};
  height: 1px;
  margin: 8px auto;
  width: 100%;
`

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : '0px')};
  overflow: hidden;
`

interface FarmCardProps {
  farm: FarmWithStakedValue
  removed: boolean
  provider?: ProviderType
  account?: string
}

const FarmCard: React.FC<FarmCardProps> = ({ farm, removed, account }) => {
  const [index, setIndex] = useState(0)
  const [showExpandableSectionPair, setShowExpandableSectionPair] = useState(false)
  const [showExpandableSectionQuoteToken, setShowExpandableSectionQuoteToken] = useState(false)
  const [showExpandableSectionToken, setShowExpandableSectionToken] = useState(false)
  const [showExpandableSectionFarmInfo, setShowExpandableSectionFarmInfo] = useState(false)
  const handleClick = (newIndex) => setIndex(newIndex)

  const { t } = useTranslation()

  const prices = useGetApiPrices()

  const tokenQuotePrice = prices[getAddress(farm.quoteToken.address).toLowerCase()]

  const tokenPrice = prices[getAddress(farm.token.address).toLowerCase()]

  const tokenQuotePriceFormatted = tokenQuotePrice
    ? `$${tokenQuotePrice.toLocaleString(undefined, { maximumFractionDigits: 4 })}`
    : '-'

  const tokenPriceFormatted = tokenPrice
    ? `$${tokenPrice.toLocaleString(undefined, { maximumFractionDigits: 4 })}`
    : '-'

  // We assume the token name is coin pair + lp e.g. TMA-BNB LP, LINK-BNB LP,
  // NAR-TMA LP. The images should be cake-bnb.svg, link-bnb.svg, nar-cake.svg
  const farmImage = farm.lpSymbol.split(' ')[0].toLocaleLowerCase()

  const switchImpedimentLossRisk = (impedimentLossRisk) => {
    switch (impedimentLossRisk) {
      case 1:
        return (
          <Tag outline scale="sm" variant="success">
            Low
          </Tag>
        )
      case 2:
        return (
          <Tag outline scale="sm" variant="binance">
            Normal
          </Tag>
        )
      case 3:
        return (
          <Tag outline scale="sm" variant="failure">
            High
          </Tag>
        )
      default:
        return (
          <Tag outline scale="sm">
            None
          </Tag>
        )
    }
  }

  const impedimentLossRiskFormatted = farm.impedimentLossRisk && switchImpedimentLossRisk(farm.impedimentLossRisk)

  const totalValueFormatted = farm.liquidity
    ? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    : '-'

  const harvestLookupNumber = farm.harvestLookup ? Number(farm.harvestLookup) : 0

  const harvestLookupFormatted =
    harvestLookupNumber !== 0 ? (
      `${Number(farm.harvestLookup).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${t('Hour(s)')}`
    ) : (
      <Text color="textDisabled">No Lookup</Text>
    )

  const minimumEarnedAmountNumber = farm.minimumEarnedAmount ? Number(farm.minimumEarnedAmount) : 0

  const minimumEarnedAmountFormatted =
    minimumEarnedAmountNumber !== 0 ? (
      `${Number(farm.minimumEarnedAmount).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${t('TMA')}`
    ) : (
      <Text color="textDisabled">0 {t('TMA')}</Text>
    )

  const minimumEarnedAmountUSDFormatted =
    tokenPrice && minimumEarnedAmountNumber !== 0
      ? ` (~ ${(Number(farm.minimumEarnedAmount) * tokenPrice).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })} ${t('USD')})`
      : null

  const amountRequiredNumber = farm.amountRequired ? Number(farm.amountRequired) : 0

  const amountRequiredFormatted =
    amountRequiredNumber !== 0 ? (
      `${Number(farm.amountRequired).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${t('TMA')}`
    ) : (
      <Text color="textDisabled">0 {t('TMA')}</Text>
    )

  const amountRequiredUSDFormatted =
    tokenPrice && amountRequiredNumber !== 0
      ? `(~ ${(Number(farm.amountRequired) * tokenPrice).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${t(
          'USD',
        )})`
      : null

  const currentMs = Date.now()
  let finishMs = 0
  let timerFormatted = ''

  if (farm.endTimestamp && farm.endTimestamp > currentMs) {
    finishMs = farm.endTimestamp
    timerFormatted = t('End in')
  } else if (farm.startTimestamp && farm.startTimestamp > currentMs) {
    finishMs = farm.startTimestamp
    timerFormatted = t('Start in')
  } else if (farm.upgradeTimestamp && farm.upgradeTimestamp > currentMs) {
    finishMs = farm.upgradeTimestamp
    timerFormatted = t('Upgrade in')
  } else if (farm.downgradeTimestamp && farm.upgradeTimestamp > currentMs) {
    finishMs = farm.upgradeTimestamp
    timerFormatted = t('Fragment in')
  } else {
    timerFormatted = undefined
  }

  const secondsUntilNextEvent = (finishMs - currentMs) / 1000

  const { minutes, hours, days } = getTimePeriods(secondsUntilNextEvent)

  const switchCategoryTag = (category) => {
    switch (category) {
      case 'core':
        return <CoreTag mb="8px" />
      case 'startup':
        return <StartupTag mb="8px" />
      case 'sports':
        return <SportsTag mb="8px" />
      case 'defi':
        return <DeFiTag mb="8px" />
      case 'yieldFarm':
        return <YieldFarmTag mb="8px" />
      case 'community':
        return <CommunityTag mb="8px" />
      default:
        return <CoreTag mb="8px" />
    }
  }

  const lpLabel = farm.lpSymbol && farm.lpSymbol.toUpperCase()
  const { earnings: earningsAsString = 0 } = farm.userData || {}
  const earnings = new BigNumber(earningsAsString)
  const earnedLabel = (
    <Text color={earningsAsString === '0' ? 'textDisabled' : ''}>
      {farm.dual ? `${earnings} ${farm.dual.earnLabel}` : `${earnings} ${t('TMA')}`}
    </Text>
  )

  const depositFeeFormatted = farm.depositFee ? (
    `${Number(farm.depositFee).toLocaleString(undefined, { maximumFractionDigits: 0 })}%`
  ) : (
    <Text color="textDisabled">0%</Text>
  )

  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: farm.quoteToken.address,
    tokenAddress: farm.token.address,
  })
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const lpAddress = farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]

  const isMetaMaskInScope = !!(window as WindowChain).ethereum?.isMetaMask
  const tokenAddress = lpAddress ? getAddress(lpAddress) : ''

  const switchRarityNumberToText = (rarity) => {
    switch (rarity) {
      case 2:
        return 'uncommon'
      case 3:
        return 'rare'
      case 4:
        return 'mythical'
      case 5:
        return 'legendary'
      case 99:
        return 'ancient'
      default:
        return 'common'
    }
  }

  const switchRenderContent = (index1) => {
    switch (index1) {
      default:
        return (
          <>
            <Heading scale="md" mt="7px" mb="3px">
              {t('Pair Info')}
            </Heading>
            <Flex justifyContent="space-between">
              <Text>{t('Total Liquidity')}:</Text>
              <Text>
                {totalValueFormatted}
                <Tooltip />
              </Text>
            </Flex>
            {/*
            TODO: Forçar o MetaMask button carregar no inicio da div e não no meio
            {isMetaMaskInScope && lpLabel && (
                <Button
                  variant="text"
                  p="0"
                  height="auto"
                  onClick={() => registerToken(tokenAddress, lpLabel, farm.quoteToken.decimals, farmImage)}
                >
                  <MetamaskIcon ml="4px" />
                </Button>
            )}
            */}
            <ExpandableSectionButton
              onClick={() => setShowExpandableSectionPair(!showExpandableSectionPair)}
              variant={switchRarityNumberToText(farm.rarity)}
              expanded={showExpandableSectionPair}
              iconOnly
            />
            <ExpandingWrapper expanded={showExpandableSectionPair}>
              <Flex justifyContent="space-between">
                <Text>
                  {t('Impediment Loss')} :<Tooltip description={t('WORKS')} />
                </Text>
                <Text>{impedimentLossRiskFormatted}</Text>
              </Flex>
              {!removed && (
                <StyledLinkExternal href={addLiquidityUrl}>{t(`Get ${lpLabel}`, { name: lpLabel })}</StyledLinkExternal>
              )}
              <StyledLinkExternal
                href={`https://bscscan.com/address/${farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]}`}
              >
                <BSCScanIcon mr="4px" /> {t('View Contract')}
              </StyledLinkExternal>
              <StyledLinkExternal href={`https://pancakeswap.info/pair/${lpAddress}`}>
                {t('See Pair Info')}
              </StyledLinkExternal>
            </ExpandingWrapper>
          </>
        )
      case 1:
        return (
          <>
            {farm.quoteToken.description && (
              <>
                <Heading scale="md" mt="7px" mb="3px">
                  {t('Token Info')}
                </Heading>
                <Text>{farm.quoteToken.description}</Text>
              </>
            )}
            <Flex justifyContent="space-between">
              <Text>{t('Token Website')}:</Text>
              <StyledLinkExternal href={farm.quoteToken.projectLink}>
                {farm.quoteToken.projectLink.replace('www.', '').replace('https://', '').replace('http://', '')}
              </StyledLinkExternal>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t('Token Price')}:</Text>
              <Text>{tokenQuotePriceFormatted}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t('Token Liquidity')}:</Text>
              <Text>
                {totalValueFormatted}
                <Tooltip />
              </Text>
            </Flex>
            {/*
            TODO: Forçar o MetaMask button carregar no inicio da div e não no meio
            {isMetaMaskInScope && lpLabel && (
                <Button
                  variant="text"
                  p="0"
                  height="auto"
                  onClick={() => registerToken(tokenAddress, lpLabel, farm.quoteToken.decimals, farmImage)}
                >
                  <MetamaskIcon ml="4px" />
                </Button>
            )}
            */}
            <ExpandableSectionButton
              onClick={() => setShowExpandableSectionQuoteToken(!showExpandableSectionQuoteToken)}
              variant={switchRarityNumberToText(farm.rarity)}
              expanded={showExpandableSectionQuoteToken}
              iconOnly
            />
            <ExpandingWrapper expanded={showExpandableSectionQuoteToken}>
              {!removed && (
                <StyledLinkExternal href={addLiquidityUrl}>
                  {t(`Buy ${farm.quoteToken.symbol.replace('wBNB', 'BNB')}`, { name: lpLabel })}
                </StyledLinkExternal>
              )}
              <StyledLinkExternal
                href={`https://bscscan.com/address/${farm.quoteToken.address[process.env.REACT_APP_CHAIN_ID]}`}
              >
                <BSCScanIcon mr="4px" /> {t('View Contract')}
              </StyledLinkExternal>
              <StyledLinkExternal
                href={`https://pancakeswap.info/token/${farm.quoteToken.address[process.env.REACT_APP_CHAIN_ID]}`}
              >
                {t('See Token Info')}
              </StyledLinkExternal>
            </ExpandingWrapper>
          </>
        )
      case 2:
        return (
          <>
            {farm.token.description && (
              <>
                <Heading scale="md" mt="7px" mb="3px">
                  {t('Token Info')}
                </Heading>
                <Text>{farm.token.description}</Text>
              </>
            )}
            <Flex justifyContent="space-between">
              <Text>{t('Token Website')}:</Text>
              <StyledLinkExternal href={farm.token.projectLink}>
                {farm.token.projectLink.replace('www.', '').replace('https://', '').replace('http://', '')}
              </StyledLinkExternal>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t('Token Price')}:</Text>
              <Text>{tokenPriceFormatted}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t('Token Liquidity')}:</Text>
              <Text>
                {totalValueFormatted}
                <Tooltip />
              </Text>
            </Flex>
            {/*
            TODO: Forçar o MetaMask button carregar no inicio da div e não no meio
            {isMetaMaskInScope && lpLabel && (
                <Button
                  variant="text"
                  p="0"
                  height="auto"
                  onClick={() => registerToken(tokenAddress, lpLabel, farm.quoteToken.decimals, farmImage)}
                >
                  <MetamaskIcon ml="4px" />
                </Button>
            )}
            */}
            <ExpandableSectionButton
              onClick={() => setShowExpandableSectionToken(!showExpandableSectionToken)}
              variant={switchRarityNumberToText(farm.rarity)}
              expanded={showExpandableSectionToken}
              iconOnly
            />
            <ExpandingWrapper expanded={showExpandableSectionToken}>
              {!removed && (
                <StyledLinkExternal href={addLiquidityUrl}>
                  {t(`Buy ${farm.token.symbol.replace('wBNB', 'BNB')}`, { name: lpLabel })}
                </StyledLinkExternal>
              )}
              <StyledLinkExternal
                href={`https://bscscan.com/address/${farm.token.address[process.env.REACT_APP_CHAIN_ID]}`}
              >
                <BSCScanIcon mr="4px" /> {t('View Contract')}
              </StyledLinkExternal>
              <StyledLinkExternal
                href={`https://pancakeswap.info/token/${farm.token.address[process.env.REACT_APP_CHAIN_ID]}`}
              >
                {t('See Token Info')}
              </StyledLinkExternal>
            </ExpandingWrapper>
          </>
        )
    }
  }

  return (
    <FCard variant={switchRarityNumberToText(farm.rarity)}>
      {farm.rarity && <StyledCardRarity variant={switchRarityNumberToText(farm.rarity)} />}
      <Flex justifyContent="space-between">
        <Tag bold outline variant={switchRarityNumberToText(farm.rarity)} mr="8px">
          {switchRarityNumberToText(farm.rarity).toUpperCase()}
        </Tag>
        {switchCategoryTag(farm.category)}
      </Flex>
      <StyledHoloCard variant={switchRarityNumberToText(farm.rarity)}>
        <Flex justifyContent="space-between" alignContent="center" mb="32px">
          {farm.type ? (
            <Text bold fontSize="14px">
              {farm.type.toUpperCase()}
            </Text>
          ) : (
            <Text bold color="textDisabled" fontSize="14px">
              (unknown)
            </Text>
          )}

          {farm.country ? (
            <Flex alignContent="center">
              <Image
                src={`/images/country/${farm.country.replace(' ', '-')}-Flag-16.png`}
                alt="brazil"
                width={16}
                height={16}
                mr="4px"
              />
              <Text bold fontSize="14px">
                {farm.country}
              </Text>
            </Flex>
          ) : (
            <Text bold fontSize="14px">
              🌐 Global
            </Text>
          )}
        </Flex>
        <Flex justifyContent="center" alignItems="center" mb="28px">
          <Image
            src={`/images/tokens/${farm.token.symbol.replace('wbnb.', 'bnb').toLowerCase()}.png`}
            alt={farm.token.symbol.replace('wBNB', 'BNB')}
            width={48}
            height={48}
            mt="-32px"
            mr="-17px"
          />
          <Image
            src={`/images/tokens/${farm.quoteToken.symbol.replace('wbnb.', 'bnb').toLowerCase()}.png`}
            alt={farm.quoteToken.symbol.replace('wBNB', 'BNB')}
            width={64}
            height={64}
            mr="7px"
          />
          <Flex flexDirection="column">
            <StyledHeading variant={switchRarityNumberToText(farm.rarity)} scale="lg" mb="4px">
              {lpLabel.split(' ')[0]}
              {farm.category === 'core' ? <VerifiedIcon color="secondary" ml="4px" /> : undefined}
            </StyledHeading>
            {farm.exchange ? (
              <Flex>
                <Image
                  src={`/images/amm/${farm.exchange.toLowerCase()}.png`}
                  alt={farm.exchange}
                  width={14}
                  height={14}
                  mr="4px"
                />
                <Text bold fontSize="15px">
                  {farm.exchange}
                </Text>
              </Flex>
            ) : (
              <Flex>
                <Text bold color="textDisabled" fontSize="11px">
                  (unknown)
                </Text>
                <Tooltip iconWidth="14px" />
              </Flex>
            )}
          </Flex>
        </Flex>
        {farm.description && (
          <>
            <Heading scale="md" mb="4px">
              {t('Description')}
            </Heading>
            <Text mb="8px" fontSize="14px">
              {farm.description}
            </Text>
          </>
        )}
        <Heading scale="md" mb="4px">
          {t('Info')}
        </Heading>
        <Flex justifyContent="space-between">
          <Text>{t('Harvest Lookup')}:</Text>
          <Text style={{ display: 'flex', alignItems: 'center' }}>{harvestLookupFormatted}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text>{t('Minimum Earned')}:</Text>
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {minimumEarnedAmountFormatted}
            <Text fontSize="14px" color="textDisabled" ml="4px">
              {minimumEarnedAmountUSDFormatted}
            </Text>
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text>{t('TMA Required')}:</Text>
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {amountRequiredFormatted}
            <Text fontSize="14px" color="textDisabled" ml="4px">
              {amountRequiredUSDFormatted}
            </Text>
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb="16px">
          <Text>{t('Deposit Fee')}:</Text>
          <Text>{depositFeeFormatted}</Text>
        </Flex>
        <Flex justifyContent="center" mb="8px">
          {timerFormatted ? (
            <Timer timerStage={timerFormatted} minutes={minutes} hours={hours} days={days} />
          ) : (
            <Heading>Open to Deposit</Heading>
          )}
        </Flex>
      </StyledHoloCard>
      <TabMenu variant={switchRarityNumberToText(farm.rarity)} activeIndex={index} onItemClick={handleClick}>
        <Tab>
          {lpLabel.split(' ')[0]} {t('Pair')}
        </Tab>
        <Tab>{farm.quoteToken.symbol.replace('wBNB', 'BNB')}</Tab>
        <Tab>{farm.token.symbol.replace('wBNB', 'BNB')}</Tab>
      </TabMenu>
      {switchRenderContent(index)}
      <Divider variant={switchRarityNumberToText(farm.rarity)} />
      <Heading scale="md" mb="3px">
        {t('Farm Info')}
      </Heading>
      {!removed && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text>{t('APY')}:</Text>
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {farm.apr ? (
              <>
                <Text fontSize="14px" color="textDisabled" mr="4px">
                  ({farm.multiplier.toLocaleString(undefined, { maximumFractionDigits: 0 }).concat('X ')}
                  {farm.apr.toLocaleString(undefined, { maximumFractionDigits: 2 }).concat('%)')}
                </Text>
                {(farm.multiplier * farm.apr).toLocaleString(undefined, { maximumFractionDigits: 2 }).concat('%')}
              </>
            ) : (
              <Skeleton height={24} width={80} />
            )}
          </Text>
        </Flex>
      )}
      <ExpandableSectionButton
        onClick={() => setShowExpandableSectionFarmInfo(!showExpandableSectionFarmInfo)}
        variant={switchRarityNumberToText(farm.rarity)}
        expanded={showExpandableSectionFarmInfo}
      />
      <ExpandingWrapper expanded={showExpandableSectionFarmInfo}>
        {account && (
          <>
            <Flex justifyContent="space-between">
              <Text>{t('Earned')}:</Text>
              <Text bold>{earnedLabel}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>
                {farm.token.symbol} {t('Staked')}:
              </Text>
              <Text style={{ display: 'flex', alignItems: 'center' }}>
                {minimumEarnedAmountFormatted}
                <Text fontSize="14px" color="textDisabled" ml="4px">
                  {minimumEarnedAmountUSDFormatted}
                </Text>
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>
                {farm.quoteToken.symbol} {t('Staked')}:
              </Text>
              <Text style={{ display: 'flex', alignItems: 'center' }}>
                {minimumEarnedAmountFormatted}
                <Text fontSize="14px" color="textDisabled" ml="4px">
                  {minimumEarnedAmountUSDFormatted}
                </Text>
              </Text>
            </Flex>
          </>
        )}

        {!removed && (
          <Flex justifyContent="space-between">
            <Text>{t('Value Locked')}:</Text>
            <Text>
              {totalValueFormatted}
              <Tooltip />
            </Text>
          </Flex>
        )}
        <CardActionsContainer farm={farm} account={account} addLiquidityUrl={addLiquidityUrl} />
      </ExpandingWrapper>
    </FCard>
  )
}

export default FarmCard
