import styled from 'styled-components'
import { BaseLayout } from 'packages/uikit'


const FlexLayout = styled(BaseLayout)`
  align-items: start;
  margin-bottom: 32px;
  
  & > div {
    grid-column: span 12;
  }
  & > * {
    min-width: 360px;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    & > * {
      max-width: 100%;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    & > * {
      max-width: 40%;
    }
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 4;
    }
    & > * {
      max-width: 31.5%;
    }
  }
`

export default FlexLayout
