import React from 'react'
import styled from 'styled-components'
import { Loading } from '../packages/uikit'
import Container from './layout/Container'

const StyledPage = styled(Container)`
  min-height: 100vh;
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

const Wrapper = styled(StyledPage)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <StyledPage>
        <Loading />
      </StyledPage>
    </Wrapper>
  )
}

export default PageLoader
