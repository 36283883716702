import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon, ChevronUpIcon, Text } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'

export interface ExpandableSectionButtonProps {
  onClick?: () => void
  variant?: string
  expanded?: boolean
  iconOnly?: boolean
}

const Wrapper = styled.div<{ variant: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: ${({ variant, theme }) => theme.colors[variant]};
  }
`

const ExpandableSectionButton: React.FC<ExpandableSectionButtonProps> = ({ onClick, variant, expanded, iconOnly }) => {
  const { t } = useTranslation()

  return (
    <Wrapper variant={variant} aria-label="Hide or show expandable content" role="button" onClick={() => onClick()}>
      {!iconOnly && (
        <Text color={variant} bold>
          {expanded ? t('Hide') : t('More')}
        </Text>
      )}
      {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Wrapper>
  )
}

ExpandableSectionButton.defaultProps = {
  expanded: false,
  iconOnly: false,
  variant: 'primary',
}

export default ExpandableSectionButton
