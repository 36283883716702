import React from 'react'
import { Card, CardBody, Heading } from 'packages/uikit'
import styled from 'styled-components'
import { Timeline } from 'react-twitter-widgets'
import { useTranslation } from 'contexts/Localization'

const StyledTwitterCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  max-height: 694px;
`

const TwitterCard = () => {
  const { t } = useTranslation()

  return (
    <StyledTwitterCard isActive>
      <CardBody>
        <Heading scale="xl" mb="24px">
          {t('Announcements')}
        </Heading>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'pancakeswap',
          }}
          options={{
            width: '100%',
            height: '650',
            theme: 'dark',
            chrome: 'noheader, nofooter',
          }}
        />
      </CardBody>
    </StyledTwitterCard>
  )
}

export default TwitterCard
