import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, Heading, Text, Flex, LinkExternal } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'

const Row = styled.div`
  margin-bottom: 4px;
`

const FarmInfoStepsCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <Flex>
      <CardBody>
        <Heading scale="lg" mb="8px">
          {t('Start in 3 simple steps:')}
        </Heading>
        <Row>
          <Heading scale="md">1. {t('Choose a Farm you Like')}</Heading>
          <Text fontSize="14px">{t('See the list of the active farms and decide which farm you want to stake.')}</Text>
        </Row>
        <Row>
          <Heading scale="md">2. {t('Add Liquidity')}</Heading>
          <Text fontSize="14px">
            {t(
              'For example, if you want to stake in TMA-BNB LP farm, you go to “Liquidity” menu and add your TMA and BNB tokens to liquidity pool. You’ll get TMA-BNB LP tokens from this step.',
            )}
          </Text>
        </Row>
        <Row>
          <Heading scale="md">3. {t('Start Farming')}</Heading>
          <Text fontSize="14px">
            {t(
              'Bring your LP tokens that you’ve got from the previous step to stake in the farm and earn much more TMA as a return!',
            )}
          </Text>
        </Row>
        <Flex justifyContent="flex-end" mt="8px">
            <LinkExternal href="https://docs.themedievalage.com/">Read More</LinkExternal>
          </Flex>
      </CardBody>
      </Flex>
    </Card>
  )
}

export default FarmInfoStepsCard
