import { darkColors } from '../../theme/colors'
import { shadows } from '../../theme/base'
import { TooltipTheme } from './types'

const darkTooltip: TooltipTheme = {
  background: darkColors.backgroundAlt,
  text: darkColors.text,
  boxShadow: shadows.white,
}

export default darkTooltip
