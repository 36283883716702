import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

import tokens from './tokens'
import { FarmConfig } from './types'

const farmsProduction: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'TMA',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'TMA-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    },
    token: tokens.zerobit,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0x2f7682b64b88149ba3250aee32db712964de5fa9',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 251,
    lpSymbol: 'TMA-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.wbnb,
    impedimentLossRisk: 2,
    rarity: 99,
    depositFee: 0,
    harvestLookup: 24,
    minimumEarnedAmount: new BigNumber(256),
    amountRequired: BIG_ZERO,
  },
  {
    pid: 252,
    lpSymbol: 'TMA-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.usdt,
    impedimentLossRisk: 2,
    rarity: 2,
    depositFee: 5,
    harvestLookup: 24,
    minimumEarnedAmount: new BigNumber(128),
    amountRequired: new BigNumber(64),
  },
  {
    pid: 403,
    lpSymbol: 'TMA-DAI LP',
    lpAddresses: {
      97: '',
      56: '0x41f049d990d38305504631c9835f6f856bf1ba67',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.dai,
    impedimentLossRisk: 2,
    rarity: 2,
    depositFee: 5,
    harvestLookup: 18,
    minimumEarnedAmount: new BigNumber(64),
    amountRequired: new BigNumber(256),
  },
  {
    pid: 402,
    lpSymbol: 'TMA-USDC LP',
    lpAddresses: {
      97: '',
      56: '0xA0387eBeA6be90849c2261b911fBBD52B4C9eAC4',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.usdc,
    impedimentLossRisk: 2,
    rarity: 3,
    depositFee: 4,
    harvestLookup: 16,
    minimumEarnedAmount: new BigNumber(32),
    amountRequired: new BigNumber(512),
  },
  {
    pid: 401,
    lpSymbol: 'TMA-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x8853e3309a31583ea438f7704681f46f0d4d909b',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.busd,
    impedimentLossRisk: 2,
    rarity: 4,
    depositFee: 3.5,
    harvestLookup: 12,
    minimumEarnedAmount: new BigNumber(32),
    amountRequired: new BigNumber(1024),
  },
  {
    pid: 401,
    lpSymbol: 'TMA-bitLTDA LP',
    lpAddresses: {
      97: '',
      56: '0x8853e3309a31583ea438f7704681f46f0d4d909b',
    },
    description: 'hello world',
    category: 'core',
    token: tokens.zerobit,
    quoteToken: tokens.themedievalage,
    impedimentLossRisk: 2,
    rarity: 5,
    depositFee: 3,
    harvestLookup: 6,
    minimumEarnedAmount: new BigNumber(32),
    amountRequired: new BigNumber(2056),
  },
]

export default farmsProduction
